import { Injectable } from '@angular/core';
import { ROUTERLINKS } from '../../../constants/router-link';
import { Router, RouterLink } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CompRouterService {

    constructor(private router: Router) { }

    public routerMap = new Map([
        ['root', ROUTERLINKS.ROOT],
        // Time
        ['time', ROUTERLINKS.TIME],
        ['time-ekstep', ROUTERLINKS.TIME_EKSTEP],
        // User
        ['user', ROUTERLINKS.USER],
        ['addUser', ROUTERLINKS.NEW_USER],
        //Training
        ['trainings', ROUTERLINKS.TRAININGS],
        ['addTrainings', ROUTERLINKS.ADDTRAININGS],
        ['feedback-report', ROUTERLINKS.TRAINING_FEEDBACK_REPORT],

        ['admin', ROUTERLINKS.ADMIN],
        ['links', ROUTERLINKS.LINKS],
        ['holiday', ROUTERLINKS.HOLIDAY],
        ['calendar', ROUTERLINKS.CALENDAR],
        ['feed', ROUTERLINKS.FEED],
        ['events', ROUTERLINKS.EVENTS],
        ['policy', ROUTERLINKS.POLICY],
        ['projects', ROUTERLINKS.PROJECT],
        ['utilization', ROUTERLINKS.UTILIZATION],
        ['pms', ROUTERLINKS.PMS],
        ['insights', ROUTERLINKS.INSIGHTS],

        ['addlinks', ROUTERLINKS.ADDLINKS],
        ['addAnnouncement', ROUTERLINKS.ADD_ANNOUNCEMENTS],
        ['accounts', ROUTERLINKS.ACCOUNTS],
        ['country', ROUTERLINKS.COUNTRY],
        ['designation', ROUTERLINKS.DESIG],
        ['tasks', ROUTERLINKS.TASKS],
        ['teams', ROUTERLINKS.TEAMS],
        ['templates', ROUTERLINKS.TEMPLATES],
        ['units', ROUTERLINKS.UNITS],
        ['lock', ROUTERLINKS.LOCK],
        ['features', ROUTERLINKS.FEATURES],
        ['excludeUsers', ROUTERLINKS.EXCLUDE_USERS],
        ['location', ROUTERLINKS.LOCATION],
        ['groupUsers', ROUTERLINKS.GROUP_USERS],
        ['publicProjects', ROUTERLINKS.PUBLIC_PROJECTS],
        ['industries', ROUTERLINKS.INDUSTRIES],
        ['conference', ROUTERLINKS.CONFERENCE_ROOMS],
        ['feedback', ROUTERLINKS.FEEDBACK_TEMPLATE],
        ['questionType', ROUTERLINKS.QUESTION_TYPE],
        ['adminSkills', ROUTERLINKS.ADMIN_SKILLS],
        ['audits', ROUTERLINKS.AUDIT_TEMPLATE],
        ['currencies', ROUTERLINKS.CURRENCY],
        ['newProject', ROUTERLINKS.NEW_PROJECT],
        ['addAuditTemplate', ROUTERLINKS.AUDIT_ADD_TEMPLATE],
        ['addOrg', ROUTERLINKS.ADD_ORGANIZATION],
        ['addTemplate', ROUTERLINKS.NEW_TEMPLATE],
        ['projectRequest', ROUTERLINKS.PROJECT_REQUEST],
        ['addProject', ROUTERLINKS.NEW_PROJECT],
        ['org', ROUTERLINKS.ORG],
        ['sites', ROUTERLINKS.SITES],
        ['orgRoles', ROUTERLINKS.ORG_ROLES],
        ['asset-list', ROUTERLINKS.ASSET_MANAGEMENT],
        ['new-asset', ROUTERLINKS.NEW_ASSET],
        ['projectLessons', ROUTERLINKS.PROJECT_LESSONS_RISKS],
        ['bulkUpload', ROUTERLINKS.BULK_UPLOAD],
        ['report', ROUTERLINKS.REPORT],
        ['userReport', ROUTERLINKS.FINANCE_USER],
        ['projectReport', ROUTERLINKS.PROJECTS],
        ['taskReport', ROUTERLINKS.TASK_REPORT],
        ['workLocationReport', ROUTERLINKS.WORK_LOCATION],
        ['businessReport', ROUTERLINKS.BUSINESS],
        ['financeReport', ROUTERLINKS.FINANCE],
        ['hrReport', ROUTERLINKS.HR],
        ['hrPublicProjectsReport', ROUTERLINKS.PUBLIC_PROJECTS_REPORT],
        ['hrLeaveSummaryReport', ROUTERLINKS.HR_LEAVE_SUMMARY],
        ['userwiseReport', ROUTERLINKS.USERS],
        ['featureReport', ROUTERLINKS.FEATURE],
        ['teamReport', ROUTERLINKS.TEAMREPORTS],
        ['countryReport', ROUTERLINKS.COUNTRY_REPORT],
        ['vendorReport', ROUTERLINKS.VENDOR_REPORT],
        ['vendorSubContractorReport', ROUTERLINKS.VENDOR_SUBCONTRACTOR_REPORT],
        ['foodReport',ROUTERLINKS.FOOD_REPORT],
        ['addBulkUploadTemplate', ROUTERLINKS.NEW_BULK_UPLOAD_TEMPLATE],
        ['dashboardBanner', ROUTERLINKS.DASHBOARD_BANNER],
        ['vendorTypes', ROUTERLINKS.VENDOR_TYPE],
        ['awardsReport',ROUTERLINKS.AWARDS_REPORT],
        ['allocationReportByUser', ROUTERLINKS.ALLOCATION_REPORT_BY_USER],
        ['allocationReportByProject', ROUTERLINKS.ALLOCATION_REPORT_BY_PROJECT],


        ['defaulterList', ROUTERLINKS.DEFAULTER_LIST],
        ['accountList', ROUTERLINKS.ACCOUNT],
        ['changePassword', ROUTERLINKS.CHANGE_PASSWORD],
        ['expense', ROUTERLINKS.EXPENSE_REPORT],
        ['expenseReport', ROUTERLINKS.ALL_EXPENSE_REPORTS],
        ['expenseCategory', ROUTERLINKS.EXPENSE_CATEGORY_TEMP],
        ['tags', ROUTERLINKS.LINK_TAGS],
        ['sharing', ROUTERLINKS.SHARED_PROJECTS],
        ['shared', ROUTERLINKS.PROJECT_GROUPS],
        ['allExpenseReports', ROUTERLINKS.ALL_EXPENSE_REPORTS],
        ['updateAnnouncement', ROUTERLINKS.UPDATE_ANNOUNCEMENTS],
        ['projectSharing', ROUTERLINKS.PROJECT_SHARING],
        ['styleguide', ROUTERLINKS.STYLEGUIDE],
        ['addEvent', ROUTERLINKS.ADD_EVENT],
        ['eventDetails', ROUTERLINKS.EVENT_DETAILS],

        ['resource', ROUTERLINKS.RESOURCE],
        ['allocationPlanner', ROUTERLINKS.ALLOCATION_PLANNER],
        ['sales', ROUTERLINKS.SALES],
        ['profile', ROUTERLINKS.PROFILE],
        ['compliance', ROUTERLINKS.COMPLIANCE],
        ['allocation', ROUTERLINKS.USER_ALLOCATION],
        ['mentor_view', ROUTERLINKS.MENTOR_VIEW],
        ['mentee', ROUTERLINKS.MENTEE_VIEW],
        // Report
        ['subscribe-report', ROUTERLINKS.SUBSCRIBE],
        ['acando-report', ROUTERLINKS.ACANDO],
        ['training-report', ROUTERLINKS.TRAINING],
        ['billingReport', ROUTERLINKS.BILLING],
        ['accountBillingReport', ROUTERLINKS.ACCOUNT_BILLING],
        ['ibu-project-details', ROUTERLINKS.IBU_PROJECT_DETAILS],
        // Award
        ['awards', ROUTERLINKS.AWARDS],
        ['availabilityModule', ROUTERLINKS.AVAILABILITY],
        // check-in
        ['checkIn', ROUTERLINKS.CHECKIN],
        ['newCheckInTemaplte', ROUTERLINKS.NEW_CHECKIN_TEMPLATE],
        ['leaderboard', ROUTERLINKS.AWARD_LEADERBOARD],
        // csat
        ['csat', ROUTERLINKS.CSAT],
        ['newCSATTemp', ROUTERLINKS.NEW_CSAT_TEMP],
        ['projectCsat', ROUTERLINKS.PROJECT_CSAT],
        //Skills
        ['skills', ROUTERLINKS.SKILL],
        //Forecast
        ['forecast', ROUTERLINKS.FORECAST],
        //IbuSpace
        ['ibu-space', ROUTERLINKS.IBUSPACE],
        //CustomerSpace
        ['customer-space', ROUTERLINKS.CUSTOMERSPACE],
        ['add-customer', ROUTERLINKS.ADD_CUSTOMER],
        ['update-customer', ROUTERLINKS.UPDATE_CUSTOMER],

        // Chatbot
        ['chat-bot', ROUTERLINKS.CHATBOT],
        ['food-register', ROUTERLINKS.FOODREGISTER],

        // FINANCE
        ['finance-module', ROUTERLINKS.FINANCE_MODULE],
        ['projectDetails', ROUTERLINKS.PROJECT_DETAILS],
        ['reports/finance/account-billing-report', ROUTERLINKS.ACCOUNT_BILLING],
        ['reports/account', ROUTERLINKS.ACCOUNT],

        // FeedService
        ['feeds', ROUTERLINKS.FEEDS],

        //KnowKronos
        ['know-kronos', ROUTERLINKS.KNOWKRONOS],

        //OnboardingForm
        ['onboarding-feedback-form', ROUTERLINKS.ONBOARDINGFORM],
        ['thor', ROUTERLINKS.THOR],
        ['general-queries', ROUTERLINKS.GENERAL_QUERIES],

        //Reports
        ['reports', ROUTERLINKS.REPORT],

        //Vendors
        ['vendors', ROUTERLINKS.VENDORS],
        ['add-vendor', ROUTERLINKS.ADD_VENDOR],
        ['update-vendor', ROUTERLINKS.UPDATE_VENDOR],
        
        // INDUSTRIES
        ['industries-module', ROUTERLINKS.INDUSTRIES_MODULE],
        ['industry-profile', ROUTERLINKS.INDUSTRY_PROFILE],

        // SOLUTIONS
        ['solutions', ROUTERLINKS.SOLUTIONS_MODULE],

        //Microlearning
        // ['micro-learning', ROUTERLINKS.MICROLEARNING]

        //APPS
        ['apps-store', ROUTERLINKS.APPS],
    ]);

    routeToContextUrls(url) {
        if (this.routerMap.has(url)) {
            this.router.navigate([this.routerMap.get(url)]);
        } else {
            this.router.navigate([this.routerMap.get(ROUTERLINKS.ROOT)]);
        }
    }

}
