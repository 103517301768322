import { UtilsService } from 'app/helpers/services';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-thor-form',
  templateUrl: './thor-form.component.html',
  styleUrls: ['./thor-form.component.scss'],
})
export class ThorFormComponent implements OnInit {
  @Input() data: any;
  actionData: any;
  disabled: boolean = false;
  @Output() messageEvent = new EventEmitter<string>();
  isPickerOpen = false;
  @ViewChildren('formInput') formInputs!: QueryList<ElementRef>;
  cancel: boolean = false;

  private valueSubjects: { [key: string]: BehaviorSubject<any> } = {};
  private initialSet: { [key: string]: boolean } = {};

  constructor(public utils: UtilsService) { }

  ngOnInit() {
    this.actionData = this.utils.getObjectDeepCopy(this.data);

    for (let i = 0; i < this.actionData.data.length; i++) {
      if (this.actionData.data[i].type === "date_list") {
        this.actionData.data[i].value = this.actionData.data[i].value.map(date => moment(date).toDate());
      }

      if (this.actionData.data[i].type === "time") {
        if (this.actionData.data[i].format === "HH:MM") {

        } else if (this.actionData.data[i].format === "MM") {
          this.actionData.data[i].value = this.convertMinutesToTime(this.actionData.data[i].value);
        }
      }

      // Initialize BehaviorSubjects for each item
      this.valueSubjects[this.actionData.data[i].key] = new BehaviorSubject<any>(this.actionData.data[i].value);
      // Initialize the initialSet flag for each item
      this.initialSet[this.actionData.data[i].key] = true;
    }

    this.setupDependencyWatcher();
  }

  ngAfterViewInit(): void {
    if (this.formInputs && this.formInputs.first) {
      this.formInputs.first.nativeElement.focus();
    }
  }

  setupDependencyWatcher() {
    this.actionData.data.forEach((item) => {
      if (item.dependency) {
        const dependentItem = this.actionData.data.find(depItem => depItem.key === item.dependency);
        if (dependentItem) {
          this.valueSubjects[dependentItem.key].subscribe((newValue) => {
            if (!this.initialSet[dependentItem.key]) {
              item.value = '';
            } else {
              this.initialSet[dependentItem.key] = false;
            }
          });
        }
      }
    });
  }

  cancelAction() {
    this.cancel = true;
  }

  onFormSubmit(form) {
    if (form.form.status === 'INVALID' && !this.cancel) {
      return;
    }

    this.disabled = true;

    let result;
    if (!this.cancel) {
      result = this.utils.getObjectDeepCopy(this.actionData.data);
      for (let i = 0; i < result.length; i++) {
        if (result[i].type === "time" && result[i].format === "MM") {
          result[i].value = this.convertTimeToMinutes(result[i].value);
        }
        if (result[i].type === "date_list" && Array.isArray(result[i].value)) {
          result[i].value = result[i].value.map(date => moment(date).format('YYYY-MM-DD'));
        }
      }
    } else {
      result = this.data.data;
      for (let i = 0; i < result.length; i++) {
        result[i].value = "";
      }
    }
    this.messageEvent.emit(result);
  }

  convertTimeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return (hours * 60) + minutes;
  }

  convertMinutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    // Use the padWithZero function to ensure two-digit formatting
    return `${this.padWithZero(hours)}:${this.padWithZero(remainingMinutes)}`;
  }

  padWithZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  getKeys(obj: any): string[] {
    if (obj === null || obj === undefined ) {
      return [];
    }
    return Object.keys(obj);
  }

  // SOLUTION TO DATE AND TIME INPUT BUG FOR BOOTSTRAP 3.3.7
  toggleDateOrTimePicker(event) {
    if (this.isPickerOpen) {
      event.target.blur();
      this.isPickerOpen = false;
    } else {
      event.target.showPicker();
      this.isPickerOpen = true;
    }
  }

  /**
 * Searches for a value in a list of key-value pairs based on a given key
 * @param list - Array of key-value pairs to search through
 * @param searchKey - The key to search for
 * @returns The corresponding value if found, undefined if not found
 */
  findValueByKey(list, searchKey) {
    return list.find(item => item.key === searchKey) ? list.find(item => item.key === searchKey).value : "";
  }

  onInputChange(item) {
    this.valueSubjects[item.key].next(item.value);
  }
}
