import { Component, Input, ElementRef, AfterViewInit, ViewChild, SimpleChanges } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'app-progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.scss']
})
export class ProgressChartComponent implements AfterViewInit {
  @Input() value: number = 0;
  @Input() max: number = 100;
  @Input() primaryColor: string = '#4CAF50';
  @Input() secondaryColor: string = '#E0E0E0';
  @Input() width: number = 150;
  @Input() height: number = 150; 

  @ViewChild('chartCanvas') chartCanvas!: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit() {
    this.createProgressChart();
  }

  ngOnChanges() {
    this.createProgressChart();
  }

  createProgressChart() {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');

    new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [this.value, this.max - this.value],
          backgroundColor: [this.primaryColor, this.secondaryColor],
          borderWidth: 0
        }]
      },
      options: {
        responsive: false, 
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        tooltips: { enabled: false },
        plugins: {
          tooltip: { enabled: false },
          legend: { display: false }
        }
      }
    });
  }
}